import React from 'react';
import { Link } from "gatsby";

import { Parallax } from "react-scroll-parallax";

import styles from '../Layouts/LayoutDesign.scss';
import localStyles from './Company.scss';

import { Icon } from "@opengov/component-library/capital";

export const Company = () => {
    return (
        <div className={`${localStyles.openGov} ${styles.block} ${styles.dark}`} id="opengov">
            <Parallax y={[-20, 10]} className={`${styles.heroBackground} ${styles.heroBackgroundTall}`} tagOuter="figure">
                <div className={localStyles.openGovBackgroundSVG}></div>
            </Parallax>

            <div className={styles.content}>
                <h2 className={localStyles.missionHeading}>Our Mission @ OpenGov</h2>
                <p className={localStyles.missionStatement}>Powering More Effective and Accountable Government</p>
            </div>

            <div className={`${styles.content}`}>
                <div className={localStyles.darkContainer}>
                    <h3 className="mt-0">We do what we say we'll do</h3>
                    <p>Whether you call it reliability, integrity, or trust, we count on each other to execute and that makes work more enjoyable.  We don’t do flakiness. It’s a lot of fun when everyone pulls their weight, holds each other accountable, and communicates like pros.  </p>
                    <h3>We drive for customer Impact</h3>
                    <p>We help customers transform their work with state of the art software and expertise.  We ask how we can do better, we listen to their feedback, and we find ways to innovate and improve their lives.</p>
                    <h3>We drive for customer Impact</h3>
                    <p>There are highs and lows and failures and successes when building a company, often every day.  No matter, because we are on a mission. We choose to do this, and we believe that what we are doing is important and will have a positive impact on communities across the country (and eventually the world).</p>
                </div>

                <div className={localStyles.learnMoreButton}>
                    <Link as="a" href="https://opengov.com" target="_blank" rel="noopener" >Learn more about OpenGov</Link>
                    <Icon icon={'arrow-right'} ariaLabel={'Learn More'} />
                </div>
            </div>
        </div>
    )
}
