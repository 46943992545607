import React from 'react';
import { Link } from "gatsby";

import { Parallax } from "react-scroll-parallax";

import homeStyles from "../../capital/Layouts/LayoutHome.scss";
import styles from '../Layouts/LayoutDesign.scss';
import localStyles from './Cards.scss';

// SVGs
import overviewIcon from "../../../images/capital.svg";
import positionsIcon from "../../../images/positions.svg";
import governmentIcon from "../../../images/government.svg";

export const Cards = () => {
    return (
        <Parallax y={[-10, 10]} className={`${localStyles.homeGridContainer} ${styles.noMargin}`} tagOuter="figure">
            <div className={`${homeStyles.homeGrid} ${localStyles.homeGrid}`}>
                <Link to="/capital" className={`${homeStyles.homeButton} ${localStyles.homeButton}`}>
                    <div className={`${homeStyles.homeButtonIcon} ${localStyles.homeButtonIcon}`}>
                        <img src={overviewIcon} alt="" role="presentation" />
                    </div>
                    <span className={`${homeStyles.homeButtonHeader} ${localStyles.homeButtonHeader}`}>Capital</span>
                    <div className={`${homeStyles.homeButtonDescription} ${localStyles.homeButtonDescription}`}>
                        Learn more about OpenGov’s Design System and components.
                    </div>
                </Link>
                <Link to="/#opengov" className={`${homeStyles.homeButton} ${localStyles.homeButton}`}>
                    <div className={`${homeStyles.homeButtonIcon} ${localStyles.homeButtonIcon}`}><img src={governmentIcon} alt="" role="presentation" /></div>
                    <span className={`${homeStyles.homeButtonHeader} ${localStyles.homeButtonHeader}`}>OpenGov</span>
                    <div className={`${homeStyles.homeButtonDescription} ${localStyles.homeButtonDescription}`}>
                        Find out more about the OpenGov mission and core values.
                    </div>
                </Link>
                <Link to="/#positions" className={`${homeStyles.homeButton} ${localStyles.homeButton}`}>
                    <div className={`${homeStyles.homeButtonIcon} ${localStyles.homeButtonIcon}`}>
                        <img src={positionsIcon} alt="" role="presentation" />
                    </div>
                    <span className={`${homeStyles.homeButtonHeader} ${localStyles.homeButtonHeader}`}>Open Positions</span>
                    <div className={`${homeStyles.homeButtonDescription} ${localStyles.homeButtonDescription}`}>
                        Come join us and make a difference in the Govtech space!
                    </div>
                </Link>
            </div>
        </Parallax>
    )
}
