import { Link } from 'gatsby';
import React, { useState, useEffect } from 'react';

import opengovIcon from '../../../images/OpenGov-Icon-White.png';
import styles from './WebsiteHeader.scss';

const WebsiteHeader = props => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    document.addEventListener('scroll', onScroll)
  
    function onScroll() {
      if (window.scrollY > 300) {
        setScrolled(true)
      } else {
        setScrolled(false)
      }
    }

    onScroll();
  }, [])

  return (
    <header className={`${styles.header} ${scrolled ? styles.scrolled : null}`} id="header">
      <img src={opengovIcon} className={styles.logo} alt="OpenGov Icon" />
      <Link to="/" className={styles.siteTitle}>
        <strong>OpenGov</strong> Design
      </Link>
      <button aria-label="Open menu" className={`${styles.menuToggle} ${menuOpen ? styles.open : null}`} onClick={() => setMenuOpen(!menuOpen)}>
        <div className={styles.bar1}></div>
        <div className={styles.bar2}></div>
        <div className={styles.bar3}></div>
      </button>
      <div className={`${styles.navContainer}  ${menuOpen ? styles.open : null}`}>
        <nav className={styles.primaryNav}>
          <ul>
            <li className={props.activeTab === 'overview' ? styles.activeTab : null}>
              <Link to="/capital">Capital Design System</Link>
            </li>
            <li className={props.activeTab === 'styles' ? styles.activeTab : null}>
              <Link to="/#opengov">About OpenGov</Link>
            </li>
            <li className={props.activeTab === 'components' ? styles.activeTab : null}>
              <Link to="/#positions">Join Us</Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export { WebsiteHeader };
