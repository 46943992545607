/* tslint:disable:no-default-export */

import React from 'react';

import { ParallaxProvider } from 'react-scroll-parallax';

import LayoutDesign from '../components/design/Layouts/LayoutDesign';

export default () => (
  <ParallaxProvider>
    <LayoutDesign/>
  </ParallaxProvider>
);
