/* tslint:disable:no-default-export */
import React from "react";

import SEO from "../../seo";

import { WebsiteHeader } from "../WebsiteHeader";
import { Hero } from "../Hero";
import { Cards } from "../Cards";
import { Principles } from "../Principles";
import { Company } from "../Company";
import { Positions } from "../Positions";
import { WebsiteFooter } from "../../capital/WebsiteFooter";

import "./base.scss";
import styles from "./LayoutDesign.scss";

const LayoutDesign = (props) => {
  if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]')
  }
  
  return (
    <>
      <SEO title="OpenGov Design" />
      <WebsiteHeader />

      <main className={styles.main}>
        <Hero />
        <Cards />
        <div className={`${styles.divider}`}></div>
        <Principles />
        <Company />
        <Positions />
        <WebsiteFooter /> 
      </main>
    </>
  )
};

export default LayoutDesign;
