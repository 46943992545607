import React from 'react';

import { Illustration } from "@opengov/component-library/capital";
import { Parallax } from "react-scroll-parallax";

import styles from '../Layouts/LayoutDesign.scss';
import localStyles from './Hero.scss';

export const Hero = () => {
    return (
        <div className={`${localStyles.hero}`}>
            <Parallax y={[-10, 20]} className={`${styles.heroBackground} ${styles.heroBackgroundTall}`} tagOuter="figure">
                <div className={localStyles.heroBackgroundSVG}></div>
            </Parallax>
            <Parallax y={[-50, -10]} className={styles.heroBackground} tagOuter="figure">
                <div className={localStyles.heroBackgroundLogoSVG}></div>
            </Parallax>
            <div className={`${localStyles.heroContent} ${styles.content}`}>
                {/* <Illustration illustration="opengov-logo" /> */}
                <h1>
                    <span className={localStyles.highlight}>Empowering public servants</span> with thorough research and thoughtful
                    solutions.
                </h1>
            </div>
        </div>
    )
}
