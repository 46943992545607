import React from 'react';

import styles from '../Layouts/LayoutDesign.scss';
import localStyles from './Positions.scss';

export const Positions = () => {
    return (
        <div className={`${styles.block}`} id="positions">
            <div className={styles.content}>
                <h2>Redwood City, Pune, Boston, that great coffee shop down the road...</h2>
                <p className={styles.subheader}>Wherever you are, we are looking for great people who have a passion for making government software better. Internet connnection required :)</p>

                <a className={localStyles.jobLink} href="https://jobs.lever.co/opengov/54fcfab9-5a70-4237-a7ff-c35c1c99bf67" target="_blank" rel="noopener">Senior Product Designer II</a>
                <p>Anywhere, USA</p>
                <a className={localStyles.jobLink} href="mailto:szaveri@opengov.com" target="_blank" rel="noopener">Senior Product Designer</a>
                <p>Pune, Maharashtra, India</p>
            </div>
        </div>
    )
}
